<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import appConfig from '@src/app.config'

export default {
    page: {
        title: 'Dashboard',
        meta: [{
            name: 'description',
            content: appConfig.description
        }],
    },
    components: {
        VuePerfectScrollbar,
    },
    data() {
        return {}
    },
    async created() {
    },
    methods: {}
}
</script>

<template>
<div class="row page-title align-items-center">
    <div class="col-sm-12 col-xl-12">
        <h4 class="mb-1 mt-0">Dashboard</h4>

    </div>
</div>
</template>
